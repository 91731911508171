<section id="permacultura">
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <header>Design em Permacultura</header>
        <p class="description">
          <q
            ><i
              >Paisagens conscientemente desenhadas que reproduzem padrões e
              relações encontradas na natureza e que, ao mesmo tempo, produzem
              alimentos, fibras e energia em abundância e suficientes para
              prover as necessidades locais.</i
            ></q
          >
          <br /><br />
          - Bill Mollison
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-carousel [images]="images"></app-carousel>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <h2>O que é a Permacultura?</h2>
        <p>
          A permacultura é um conceito capaz de (re)desenhar a vida humana em
          completa harmonia com a Terra. O desenho de sistemas vivos permanentes
          e resilientes, inspirados nos padrões da natureza, com passos simples,
          lentos, mas fortes e consistentes.
        </p>
        <p>
          Este método foi desenvolvido nos anos 70, do século passado, na
          Austrália, por Bill Mollison e o aluno David Holmgren. Surge como
          resposta aos sistemas industriais insustentáveis e práticas agrícolas
          de exploração da terra, e lentamente vai-se propagando por todo o
          mundo, como sistema de design para a resiliência da vida humana no
          planeta. Tem como ética: Cuidar da Terra; Cuidar das Pessoas e
          Partilha de Excedentes
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <h2>Permacultura no Bem da Terra</h2>
        <p>
          No Bem da Terra construímos e ajudamos a construir um mundo melhor
          através da Permacultura. Desenhamos espaços seguindo os 12 princípios
          da Permacultura, com soluções criativas e sempre inspirado nos padrões
          da natureza, para que cada espaço conte uma história.
          Transformamos hortas em bosques de alimentos e jardins em nichos de
          interação do ser humano com a natureza. Construímos jardins
          comestíveis e sensoriais. Ajudamos as pessoas a fazer o próprio
          desenho e a coloca-lo em prática.
        </p>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <h2>Serviços</h2>
    <div class="col-md-6">
      <div class="flex-container">
        <ul>
          <li>Design e consultadoria em Permacultura</li>
          <li>Transformação de espaços</li>
          <li>Construção de bosques humanos</li>
          <li>Jardins comestíveis</li>
        </ul>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-button-cta>Consultar</app-button-cta>
      </div>
    </div>
  </div>
</section>
