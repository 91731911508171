<app-navbar></app-navbar>
<main id="main" (click)="closeMenu()">
  <app-logo></app-logo>
  <app-welcome-section></app-welcome-section>
  <span></span>
  <div class="flex-container pb-0">
    <app-polygon-svg></app-polygon-svg>
  </div>
  <app-permacultura-section></app-permacultura-section>
  <app-escola-section></app-escola-section>
  <app-caminhadas-section></app-caminhadas-section>
  <app-bosque-section></app-bosque-section>
  <app-servicos-section></app-servicos-section>
  <app-agenda-section></app-agenda-section>
  <app-contactos-section></app-contactos-section>
</main>
<app-scroll-top></app-scroll-top>
