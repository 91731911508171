export const evento5 =
`## Dia Da Terra

(22 e 25 de abril)



Celebração do Dia Mundial da Terra e dos 5 Anos de Bem da Terra.
O Eco-Encontro Dia Terra é uma iniciativa cidadã, pela promoção da sustentabilidade e do cuidado da Terra. Um encontro para valorizar a TERRA - como suporte, alimento, abrigo, arte, história, ... uma viagem da terra para a terra.
O evento está dividido em dois dias, uma pequena ação no dia 22 de abril, com uma Caminhada Sensorial, no final do dia e um dia completo de atividades no dia 25 de abril.

As inscrições para este evento são muito limitadas e obrigatórias, os interessados devem contactar diretamente o Bem da Terra por e-mail: coopbemdaterra@gmail.com ou tlm: 964856933.`
