<section id="agenda">
  <div class="flex-container">
    <header>Próximos Eventos</header>
    <app-event-card
      class="col-md-6"
      *ngFor="let evento of eventos"
      [evento]="evento"
    >
    </app-event-card>
  </div>
</section>
