<section id="caminhadas_sensoriais">
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <header>Caminhadas Sensoriais</header>
        <p class="description">
          <q
            ><i
              >…saber não tem sequer metade da importância de sentir. Se os
              factos são as sementes que mais tarde produzem conhecimento e
              sabedoria, nesse caso as emoções e as impressões dos sentidos são
              o solo fértil no qual haverão de crescer as sementes.</i
            ></q
          >
          <br /><br />
          - Rachel Carson
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-carousel [images]="images"></app-carousel>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <h2>Como aprender/sentir a Natureza?</h2>
        <p>
          O ato de caminhar torna as pessoas mais saudáveis, mais felizes e mais
          inteligentes e faze-lo em ambiente natural é ainda mais produtivo.
          Caminhar entre as árvores, ouvir os sons da natureza, observar a
          paisagem, sentir o aroma das flores, perceber as texturas tem um
          impacto muito positivo na vida das pessoas, melhora a saúde e o
          bem-estar e tem a capacidade de (re)conectar o ser humano com a Terra.
        </p>
        <p>Só sentindo a Natureza podemos protege-la.</p>
        <p>
          As Caminhadas Sensoriais são um convite para uma ação de descoberta e
          (re)conexão com a Natureza, um encontro entre o Ser Humano e os
          elementos naturais.
        </p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="flex-container">
        <h2>Caminhadas Bem da Terra</h2>
        <p>
          O Bem da Terra oferece Caminhadas Sensoriais em diversos habitats
          (floresta, bosque, rio, …), nas diferentes estações do ano e em
          diferentes momentos do dia, que para além do caminho em si, inclui
          momentos experienciais de interação e exploração do ecossistema
          envolvido, através da arte e outros desafios sensoriais. Caminhadas
          para pessoas de todas as idades e com todos os tipos de limitações. Um
          programa recomendado para famílias.
        </p>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <h2>Serviços</h2>
    <div class="col-md-6">
      <ul>
        <li>Caminhadas Sensoriais</li>
        <li>Exercícios de (re)conexão com a Natureza</li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-button-cta>Participar</app-button-cta>
      </div>
    </div>
  </div>
</section>
