<section id="bosque_de_alimentos">
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <header>Bosque de Alimentos</header>
        <p class="description">
          <q
            ><i
              >Resgatar e amar um pedaço da mãe terra é muito mais profundo do
              que simplesmente criar sistemas para manter vivo o nosso corpo
              físico: é o resgate profundo da relação do homem com a natureza,
              de substituir o tempo de relógio – nossa escravidão – por
              ritmos.</i
            ></q
          >
          <br /><br />
          - Marsha Hanzi
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-carousel [images]="images"></app-carousel>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <h2>
          Como produzir comida em abundância e em equilíbrio com a Natureza?
        </h2>
        <p>
          O Bosque de Alimentos do Bem da Terra é um espaço de 450m2 em sucessão
          ecológica, que segue os princípios da agricultura natural de Masanobu
          Fukuoka, a agricultura da “não ação” (Não revirar a terra; Não
          arrancar ervas daninhas; Não usar fertilizantes e adubos químicos; Não
          usar pesticidas; Não colocar a terra exposta ao sol). Um espaço de
          estudo e construção permanente, num processo de aceleração da sucessão
          ecológica e de regeneração do solo. Onde pode ser observado os 7
          estratos da floresta (Emergentes, Alto, Médio, Baixo, Rasteiro,
          Rizomas e Trepadeiras, sentida a harmonia do bosque nos pequenos
          nichos de interação e descanso e saboreada toda a abundância de
          alimentos.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <h2>Bosque do Bem da Terra</h2>
        <p>
          O Bem da Terra disponibiliza um bosque como sala de aula ao ar livre,
          para trabalhar o Saber Sentir. Um Bosque para ser explorado
          individualmente ou em grupo, para inspirar e religar. Um Bosque de
          alimentos que oferece muita fruta e ervas aromáticas, bem como
          aprendizagem e emoções. É o espaço sede do Bem da Terra, que além de
          todo o ecossistema criado ainda oferece um Dome para estadia e
          desenvolvimento de diversas atividades individuais, familiares ou
          grupos.
        </p>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <h2>Serviços</h2>
    <div class="col-md-6">
      <ul>
        <li>Sala de Aula ao Ar Livre</li>
        <li>Experiências em Permacultura</li>
        <li>Visitas de grupo e/ou famílias</li>
        <li>Agricultura Natural</li>
        <li>Ervas Aromáticas</li>
        <li>Estadia em Dome</li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-button-cta>Visitar</app-button-cta>
      </div>
    </div>
  </div>
</section>
