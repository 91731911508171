import { Component } from '@angular/core';

@Component({
  selector: 'phone-icon',
  templateUrl: './phone-icon.component.html',
  styleUrls: ['./phone-icon.component.css']
})
export class PhoneIconComponent  {

}
