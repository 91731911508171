<carousel
  *ngIf="viewInited"
  [images]="images"
  [cellsToShow]="1"
  [loop]="true"
  [autoplay]="true"
  [autoplayInterval]="5000"
  [arrows]="false"
  [height]="360"
  [transitionDuration]="600"
  [margin]="0"
  [overflowCellsLimit]="2"
>
</carousel>
