<section id="servicos">
  <div class="flex-container flex-reverse">
    <div class="col-md-6">
      <header>De que serviços dispõe o Bem da Terra?</header>
      <p>
        O Bem da Terra desenvolve os seguintes produtos e serviços que promovem
        hábitos de vida em harmonia com a natureza:
      </p>
      <ul>
        <li>
          <a href="#permacultura"
            >Design em Permacultura - Desenhar vidas em harmonia com a
            Natureza</a
          >
        </li>
        <li>
          <a href="#ecoalfabetização"
            >Escola Bem da Terra – Formação na Educação para a
            Sustentabilidade</a
          >
        </li>
        <li>
          <a href="#caminhadas_sensoriais"
            >Caminhadas Sensoriais – Religação do ser humano à Natureza pela
            caminhada</a
          >
        </li>
        <li>
          <a href="#bosque_de_alimentos"
            >Bosque de Alimentos – Produção de alimentos com regeneração da
            terra e Sala de aula ao ar livre</a
          >
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <app-carousel [images]="images"></app-carousel>
    </div>
  </div>
</section>
