<section id="ecoalfabetização">
  <div class="flex-container flex-reverse">
    <div class="col-md-6">
      <div class="flex-container">
        <header>Escola Bem da Terra (Ecoalfabetização)</header>
        <p class="description">
          <q
            ><i
              >A sobrevivência da humanidade dependerá da nossa alfabetização
              ecológica (conhecimento dos princípios básicos da ecologia), da
              nossa capacidade para entender esses princípios (interdependência,
              reciclagem, parceria, flexibilidade, diversidade) e a
              sustentabilidade como consequência de todos.</i
            ></q
          >
          <br /><br />
          - Fritjof Capra
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-carousel [images]="images"></app-carousel>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="col-md-6">
      <div class="flex-container">
        <h2>
          Como é que a ecoalfabetização pode ajudar as pessoas em geral e os
          professores/escolas em particular?
        </h2>
        <p>
          A ecoalfabetização é uma estratégia educativa onde o ser humano
          adquire ferramentas, através do conhecimento dos princípios básicos da
          ecológica (aprender com a Natureza), para diminuir o fosso entre o
          Saber (conceitos adquiridos) e o Fazer (ação cotidiana). Uma educação
          que vai além da aquisição de conhecimentos, leva o estudante numa
          viagem de transformação que incentiva o desenvolvimento de habilidades
          e valores que orientarão e motivarão para estilos de vida sustentáveis
          (trabalhar a resiliência).
        </p>
        <p>
          A ecoalfabetização é um conceito desenvolvido pelo físico Fritjof
          Capra, no Centro de Ecoalfabetização, na Califórnia, que visa a busca
          de formas de operacionalizar a sustentabilidade ecológica,
          inspirando-se nos sistemas naturais que são por essência sustentáveis.
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <h2>Ecoalfabetização no Bem da Terra</h2>
        <p>
          Grande parte da aprendizagem requer a presença de um facilitador, que
          orienta na viagem e torna os conceitos complexos em meras conexões da
          realidade em que vivemos. O Bem da Terra atua na Educação para a
          Sustentabilidade, onde facilitamos um conjunto de Cursos (Introdução à
          Permacultura, Agricultura Natural,…), Oficinas (Cosmética Natural,
          Detergentes Ecológicos, Criar Solos,…), Workshops (Jardins
          Comestíveis, Horta Mandala,…) e Campos de Trabalho verdadeiramente
          transformadores, que vão facultar as ferramentas necessárias para
          tornar a vida das pessoas mais sustentável e em plena harmonia com a
          Natureza.
        </p>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <h2>Serviços</h2>
    <div class="col-md-6">
      <ul>
        <li>Cursos</li>
        <li>Workshops</li>
        <li>Oficinas</li>
        <li>Campos de Trabalho</li>
        <li>Conversas e Palestras</li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="flex-container">
        <app-button-cta>Contactar</app-button-cta>
      </div>
    </div>
  </div>
</section>
