<section id="contactos">
  <div class="flex-container">
    <div class="col-md-6 justify-left">
      <header>Contactos:</header>
      <div class="row">
        <phone-icon class="col-1"></phone-icon>
        <a href="tel:964856933">964 856 933</a>
      </div>
      <div class="row">
        <email-icon class="col-1"></email-icon>
        <a href="mailto:coopbemdaterra@gmail.com">coopbemdaterra@gmail.com</a>
      </div>
      <div class="row">
        <facebook-icon class="col-1"></facebook-icon>
        <a
          href="https://www.facebook.com/coopbemdaterra/"
          target="_blank"
          rel="noreferrer"
          >facebook.com/coopbemdaterra/</a
        >
      </div>
      <div class="row">
        <youtube-icon class="col-1"></youtube-icon>
        <a
          href="https://www.youtube.com/channel/UCkbJ3Gpa8pOQFS90-m2hg4w"
          target="_blank"
          rel="noreferrer"
          >Canal Youtube do Bem da Terra</a
        >
      </div>
      <div class="row">
        <address-icon class="col-1"></address-icon>
        <address class="col-11">
          Bosque Bem da Terra,
          <br />
          Rua D. João Miranda Teixeira,
          <br />
          4650-801 Vila Verde - Felgueiras
        </address>
      </div>
    </div>
    <div class="col-md-6">
      <!-- <form
        id="email-form"
        action="mailto:social.jruipinto@gmail.com"
        method="post"
        enctype="text/plain"
      >
        <div class="my-input">
          <label for="email" id="email-label">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div class="my-input">
          <label for="message" id="message-label">Mensagem</label>
          <textarea name="comment" id="message" cols="" rows="6"></textarea>
        </div>
        <div class="my-input">
          <input class="btn" type="submit" value="Enviar" />
        </div>
      </form> -->
    </div>
  </div>
</section>
