import { Component } from '@angular/core';

@Component({
  selector: 'app-polygon-svg',
  templateUrl: './polygon-svg.component.html',
  styleUrls: ['./polygon-svg.component.css']
})
export class PolygonSvgComponent  {

}
