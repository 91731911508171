<!-- <svg viewBox="0 0 422 261" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0)">
		<path d="M167.963 261H422V1.60123L167.963 71.2546V261Z" fill="#F2F2F2" />
		<path d="M198.751 169.33H398.352V251.393H198.751V169.33Z" fill="#3F3D56" />
		<path
			d="M241.98 153.977C226.651 153.977 214.224 143.012 214.224 129.484C214.224 115.957 226.651 104.991 241.98 104.991C257.309 104.991 269.736 115.957 269.736 129.484C269.736 143.012 257.309 153.977 241.98 153.977Z"
			fill="#15A587" />
		<path d="M242.23 173.779L242.003 129.35" stroke="#3F3D56" stroke-width="2" stroke-miterlimit="10" />
		<path
			d="M242.003 135.633C238.071 135.633 234.883 132.82 234.883 129.35C234.883 125.88 238.071 123.067 242.003 123.067C245.935 123.067 249.123 125.88 249.123 129.35C249.123 132.82 245.935 135.633 242.003 135.633Z"
			fill="#3F3D56" />
		<path d="M242.143 156.732C242.143 156.732 243.061 137.424 263.924 139.584L242.143 156.732Z" fill="#3F3D56" />
		<path
			d="M358.254 104.803C342.924 104.803 330.498 93.8369 330.498 80.3098C330.498 66.7826 342.924 55.8167 358.254 55.8167C373.583 55.8167 386.01 66.7826 386.01 80.3098C386.01 93.8369 373.583 104.803 358.254 104.803Z"
			fill="#15A587" />
		<path d="M359.824 170.732L358.227 80.2827" stroke="#3F3D56" stroke-width="2" stroke-miterlimit="10" />
		<path
			d="M358.228 86.5656C354.295 86.5656 351.108 83.7527 351.108 80.2827C351.108 76.8127 354.295 73.9997 358.228 73.9997C362.16 73.9997 365.348 76.8127 365.348 80.2827C365.348 83.7527 362.16 86.5656 358.228 86.5656Z"
			fill="#3F3D56" />
		<path d="M358.68 105.867C358.68 105.867 359.356 86.5509 380.244 88.5078L358.68 105.867Z" fill="#3F3D56" />
		<path d="M359.794 169.005C359.794 169.005 354.562 131.949 380.973 129.791" stroke="#3F3D56" stroke-width="2"
			stroke-miterlimit="10" />
		<path
			d="M380.974 136.074C377.041 136.074 373.854 133.261 373.854 129.791C373.854 126.321 377.041 123.508 380.974 123.508C384.906 123.508 388.094 126.321 388.094 129.791C388.094 133.261 384.906 136.074 380.974 136.074Z"
			fill="#3F3D56" />
		<path
			d="M298.564 50.427C283.235 50.427 270.808 39.4611 270.808 25.934C270.808 12.4068 283.235 1.44092 298.564 1.44092C313.893 1.44092 326.32 12.4068 326.32 25.934C326.32 39.4611 313.893 50.427 298.564 50.427Z"
			fill="#15A587" />
		<path d="M298.547 174.22V26.024" stroke="#3F3D56" stroke-width="2" stroke-miterlimit="10" />
		<path
			d="M298.546 32.2367C294.614 32.2367 291.426 29.4237 291.426 25.9537C291.426 22.4838 294.614 19.6708 298.546 19.6708C302.478 19.6708 305.666 22.4838 305.666 25.9537C305.666 29.4237 302.478 32.2367 298.546 32.2367Z"
			fill="#3F3D56" />
		<path
			d="M320.415 139.457C316.483 139.457 313.295 136.644 313.295 133.174C313.295 129.704 316.483 126.891 320.415 126.891C324.347 126.891 327.535 129.704 327.535 133.174C327.535 136.644 324.347 139.457 320.415 139.457Z"
			fill="#3F3D56" />
		<path d="M298.547 58.0474C298.547 58.0474 299.565 38.7427 320.416 40.9866L298.547 58.0474Z" fill="#3F3D56" />
		<path d="M298.547 112.774C298.547 112.774 299.565 93.4694 320.416 95.7133L298.547 112.774Z" fill="#3F3D56" />
		<path d="M298.547 172.493C298.547 172.493 293.97 134.969 320.416 133.174" stroke="#3F3D56" stroke-width="2"
			stroke-miterlimit="10" />
		<path
			d="M276.241 80.5207C272.309 80.5207 269.121 77.7078 269.121 74.2378C269.121 70.7678 272.309 67.9548 276.241 67.9548C280.174 67.9548 283.361 70.7678 283.361 74.2378C283.361 77.7078 280.174 80.5207 276.241 80.5207Z"
			fill="#3F3D56" />
		<path d="M298.109 111.555C298.109 111.555 302.687 76.0329 276.241 74.2378" stroke="#3F3D56" stroke-width="2"
			stroke-miterlimit="10" />
		<path
			d="M239.184 60.046C241.158 60.046 243.087 59.5295 244.729 58.5618C246.37 57.5941 247.649 56.2187 248.404 54.6095C249.16 53.0002 249.357 51.2295 248.972 49.5212C248.587 47.8128 247.637 46.2436 246.241 45.012C244.845 43.7803 243.067 42.9416 241.131 42.6017C239.195 42.2619 237.188 42.4363 235.365 43.1029C233.541 43.7695 231.983 44.8982 230.886 46.3465C229.789 47.7948 229.204 49.4975 229.204 51.2393C229.207 53.5742 230.259 55.8127 232.13 57.4637C234.001 59.1147 236.538 60.0434 239.184 60.046ZM239.184 43.2331C240.978 43.2331 242.733 43.7027 244.225 44.5824C245.717 45.4621 246.879 46.7125 247.566 48.1755C248.253 49.6384 248.433 51.2482 248.082 52.8012C247.732 54.3542 246.868 55.7808 245.599 56.9005C244.331 58.0201 242.714 58.7826 240.954 59.0916C239.194 59.4005 237.37 59.2419 235.712 58.636C234.054 58.03 232.637 57.0039 231.64 55.6872C230.643 54.3706 230.111 52.8227 230.111 51.2393C230.114 49.1167 231.071 47.0817 232.772 45.5808C234.473 44.0798 236.779 43.2356 239.184 43.2331Z"
			fill="#3F3D56" />
		<path
			d="M396.143 17.6135C398.117 17.6135 400.046 17.097 401.688 16.1293C403.329 15.1616 404.608 13.7862 405.363 12.177C406.119 10.5677 406.316 8.79698 405.931 7.08864C405.546 5.3803 404.596 3.81109 403.2 2.57944C401.804 1.34779 400.026 0.509033 398.09 0.169223C396.154 -0.170588 394.147 0.00381695 392.324 0.670379C390.5 1.33694 388.942 2.46572 387.845 3.91398C386.748 5.36225 386.163 7.06494 386.163 8.80675C386.166 11.1416 387.218 13.3801 389.089 15.0312C390.96 16.6822 393.497 17.6109 396.143 17.6135ZM396.143 0.800618C397.937 0.800618 399.692 1.27017 401.184 2.14989C402.676 3.02962 403.838 4.28001 404.525 5.74294C405.212 7.20587 405.392 8.81564 405.041 10.3687C404.691 11.9217 403.827 13.3483 402.558 14.4679C401.29 15.5876 399.673 16.3501 397.913 16.6591C396.153 16.968 394.329 16.8094 392.671 16.2035C391.013 15.5975 389.596 14.5713 388.599 13.2547C387.602 11.9381 387.07 10.3902 387.07 8.80675C387.073 6.68414 388.03 4.64916 389.731 3.14824C391.432 1.64733 393.738 0.803045 396.143 0.800618Z"
			fill="#3F3D56" />
		<path
			d="M167.963 148.113C169.937 148.113 171.867 147.597 173.508 146.629C175.149 145.662 176.428 144.286 177.184 142.677C177.939 141.068 178.137 139.297 177.752 137.589C177.367 135.88 176.416 134.311 175.02 133.079C173.625 131.848 171.846 131.009 169.91 130.669C167.974 130.329 165.968 130.504 164.144 131.17C162.321 131.837 160.762 132.966 159.665 134.414C158.569 135.862 157.983 137.565 157.983 139.307C157.986 141.642 159.039 143.88 160.91 145.531C162.781 147.182 165.317 148.111 167.963 148.113ZM167.963 131.301C169.758 131.301 171.512 131.77 173.004 132.65C174.496 133.53 175.659 134.78 176.345 136.243C177.032 137.706 177.212 139.316 176.862 140.869C176.512 142.422 175.648 143.848 174.379 144.968C173.11 146.088 171.493 146.85 169.733 147.159C167.973 147.468 166.149 147.309 164.491 146.703C162.834 146.097 161.417 145.071 160.42 143.755C159.423 142.438 158.891 140.89 158.891 139.307C158.893 137.184 159.85 135.149 161.551 133.648C163.252 132.147 165.558 131.303 167.963 131.301Z"
			fill="#3F3D56" />
		<path
			d="M210.152 79.2607C206.895 79.2607 204.254 76.9308 204.254 74.0567C204.254 71.1827 206.895 68.8528 210.152 68.8528C213.409 68.8528 216.049 71.1827 216.049 74.0567C216.049 76.9308 213.409 79.2607 210.152 79.2607Z"
			fill="#3F3D56" />
		<path
			d="M163.88 174.133C160.623 174.133 157.983 171.804 157.983 168.929C157.983 166.055 160.623 163.725 163.88 163.725C167.137 163.725 169.777 166.055 169.777 168.929C169.777 171.804 167.137 174.133 163.88 174.133Z"
			fill="#3F3D56" />
		<path
			d="M167.963 261V169.33H210.151C210.151 212.22 249.553 246.989 298.157 246.989C346.761 246.989 386.163 212.22 386.163 169.33H422V261H167.963Z"
			fill="#3F3D56" />
		<path
			d="M298.156 246.989C249.552 246.989 210.151 212.22 210.151 169.33H386.162C386.162 212.22 346.761 246.989 298.156 246.989Z"
			fill="#15A587" />
		<path
			d="M48.3008 201.977C4.9296 182.618 -12.445 135.898 9.49355 97.6257L166.555 167.732C144.616 206.005 91.6721 221.337 48.3008 201.977Z"
			fill="#3F3D56" />
		<path
			d="M45.1205 57.4453C44.8282 57.2701 44.5457 57.083 44.271 56.8875C44.8433 58.3997 45.7658 59.7902 46.9798 60.9708C47.2638 61.2468 47.567 61.5052 47.8784 61.755C47.8846 61.6917 47.8915 61.6284 47.8953 61.5652C47.9316 60.9528 47.881 60.3083 47.8715 59.6776C46.7898 59.1089 45.8531 58.3489 45.1205 57.4453Z"
			fill="url(#paint0_linear)" />
		<path
			d="M40.8637 35.1112C40.2035 34.1293 39.8228 33.0209 39.7542 31.8808C39.6857 30.7406 39.9312 29.6028 40.4699 28.5648C41.0086 27.5267 41.8243 26.6193 42.8472 25.9203C43.8702 25.2212 45.0699 24.7514 46.3436 24.5509C47.6173 24.3505 48.9271 24.4254 50.1609 24.7693C51.3946 25.1132 52.5155 25.7158 53.4276 26.5256C54.3397 27.3353 55.0158 28.3281 55.3981 29.4188C55.7804 30.5096 55.8574 31.6658 55.6225 32.7886C57.9117 33.7506 59.8273 35.2885 61.1285 37.2088C62.4296 39.1291 63.0582 41.3461 62.9351 43.5808C64.0286 45.0622 64.7152 46.7484 64.9401 48.5052C65.165 50.2619 64.9221 52.0408 64.2306 53.7001C63.5392 55.3594 62.4183 56.8534 60.9572 58.0631C59.4961 59.2728 57.7352 60.1648 55.8146 60.668C53.894 61.1713 51.8667 61.272 49.8943 60.9621C47.9218 60.6521 46.0586 59.9401 44.4529 58.8828C42.8472 57.8254 41.5435 56.4518 40.6454 54.8714C39.7473 53.2911 39.2797 51.5475 39.2798 49.7795C39.2798 49.5984 39.2858 49.4186 39.2953 49.2396C37.6989 47.0777 36.9844 44.5006 37.2695 41.9325C37.5546 39.3645 38.8222 36.9586 40.8637 35.1112Z"
			fill="#2F2E41" />
		<g opacity="0.1">
			<path opacity="0.1"
				d="M55.7285 30.9964C55.6927 30.9809 55.6582 30.9632 55.6222 30.9479C55.6478 30.8256 55.6654 30.7013 55.6838 30.5771C55.6876 30.6031 55.6931 30.6285 55.6966 30.6545C55.7123 30.7691 55.7192 30.8826 55.7285 30.9964Z"
				fill="black" />
			<path opacity="0.1"
				d="M37.3013 42.5584C37.5355 44.2854 38.218 45.9414 39.2956 47.3977C39.2861 47.5767 39.2802 47.7565 39.2802 47.9376C39.2797 50.8706 40.5651 53.69 42.8673 55.8055C45.1695 57.921 48.3098 59.1685 51.6309 59.2868C54.9519 59.4052 58.196 58.3851 60.684 56.4402C63.1719 54.4954 64.7107 51.7766 64.9782 48.8531C65.0063 49.1589 65.0234 49.4673 65.0234 49.7795C65.0234 52.792 63.6673 55.681 61.2534 57.8112C58.8395 59.9413 55.5656 61.1379 52.1518 61.1379C48.738 61.1379 45.4641 59.9413 43.0502 57.8112C40.6363 55.681 39.2802 52.792 39.2802 49.7795C39.2802 49.5984 39.2861 49.4186 39.2956 49.2396C37.7349 47.1291 37.0166 44.6194 37.2545 42.108C37.2683 42.258 37.2806 42.4077 37.3013 42.5584Z"
				fill="black" />
			<path opacity="0.1"
				d="M39.8072 30.5681C39.9462 31.5228 40.3057 32.4421 40.8633 33.2693C40.6383 33.4733 40.4255 33.687 40.2186 33.9051C39.7798 32.838 39.6387 31.6934 39.8072 30.5681H39.8072Z"
				fill="black" />
		</g>
		<path
			d="M92.7325 156.974C92.671 157.62 92.4726 158.251 92.1475 158.832C91.9819 159.122 91.7409 159.373 91.4443 159.564C91.1477 159.756 90.8039 159.883 90.441 159.935C89.7739 159.939 89.1198 159.771 88.5584 159.453C87.4125 158.925 86.223 158.311 85.6249 157.3C85.0458 156.321 85.1291 155.152 85.227 154.051L85.9965 145.39C87.8502 145.345 89.7128 145.346 91.5649 145.435C93.3996 145.523 93.7217 145.904 93.4664 147.485C92.9582 150.63 93.2056 153.826 92.7325 156.974Z"
			fill="#FBBEBE" />
		<path
			d="M38.3464 162.828L33.9352 169.117C33.7457 169.482 33.4013 169.768 32.9759 169.913C32.6594 169.953 32.3366 169.904 32.0542 169.771C30.5831 169.204 29.4571 168.127 28.3767 167.079C27.6436 166.368 26.886 165.618 26.6108 164.688C26.4684 164.183 26.4746 163.655 26.6289 163.154C26.7832 162.652 27.0803 162.193 27.4921 161.822C28.0072 161.426 28.5641 161.075 29.1551 160.773C32.6421 158.698 34.2158 154.755 37.6581 152.622C37.8568 152.476 38.0988 152.384 38.3552 152.357C38.6112 152.37 38.8584 152.444 39.0717 152.569C40.6402 153.328 42.2088 154.087 43.7774 154.845C45.8152 155.829 45.1692 155.945 43.6217 157.004C41.3421 158.565 39.9184 160.747 38.3464 162.828Z"
			fill="#FBBEBE" />
		<path
			d="M83.1579 151.965C83.804 151.785 84.528 151.623 85.1372 151.885C85.422 152.039 85.6631 152.248 85.842 152.497C86.0208 152.746 86.1325 153.027 86.1686 153.32C86.2915 153.898 86.2766 154.498 86.4584 155.064C86.7651 156.018 87.5887 156.763 88.3826 157.465C88.7032 157.802 89.1202 158.058 89.5905 158.207C90.2265 158.35 90.903 158.087 91.5497 158.187C91.9266 158.273 92.2698 158.447 92.5422 158.692C92.8147 158.937 93.006 159.243 93.0955 159.577C93.2636 160.247 93.2892 160.939 93.171 161.617L92.6702 166.444C92.5819 167.033 92.5751 167.628 92.6499 168.218C92.8344 169.279 93.5333 170.211 93.9211 171.228C94.3089 172.246 94.2873 173.545 93.3168 174.192C92.9779 174.392 92.5877 174.513 92.1819 174.544C91.7762 174.576 91.368 174.517 90.9948 174.373C90.2517 174.077 89.5941 173.636 89.0715 173.084C86.1005 170.225 84.7741 166.353 82.4633 163.053C81.8735 162.29 81.3458 161.491 80.8842 160.661C80.5671 160.02 80.3656 159.34 80.1089 158.679C79.7545 157.794 79.3292 156.934 78.8365 156.103C78.5617 155.627 77.5094 154.483 77.7304 153.952C78.1076 153.046 82.1572 152.243 83.1579 151.965Z"
			fill="#2F2E41" />
		<path
			d="M29.5886 165.28C30.2569 166.386 31.3727 167.23 32.714 167.644C33.1033 167.736 33.4656 167.902 33.7763 168.129C33.9803 168.349 34.1067 168.616 34.1398 168.899C34.1728 169.182 34.111 169.467 33.9622 169.719C33.655 170.217 33.2556 170.667 32.7804 171.05C30.1133 173.541 28.3494 176.677 27.7149 180.058C27.557 180.911 27.4537 181.817 26.8753 182.515C25.7453 183.877 23.3399 183.788 21.6411 183.012C21.1222 182.802 20.6718 182.479 20.3319 182.075C20.0488 181.636 19.8687 181.152 19.8027 180.65C19.4958 179.407 19.3264 178.141 19.297 176.87C19.3299 174.47 20.3515 172.175 20.8395 169.814C21.532 166.463 21.185 162.854 22.9535 159.826C23.1297 159.47 23.408 159.161 23.7609 158.93C24.2035 158.725 24.6996 158.627 25.1991 158.645C26.7969 158.587 28.3956 158.575 29.9954 158.608C30.4435 158.617 30.9676 158.667 31.1997 159.005C31.4371 159.351 31.2296 159.793 31.021 160.153C30.0312 161.863 28.4268 163.229 29.5886 165.28Z"
			fill="#2F2E41" />
		<path
			d="M67.5409 105.215C67.9878 105.54 68.2907 105.994 68.3966 106.496C68.5024 106.999 68.4045 107.519 68.1199 107.964C67.7656 108.482 67.1301 108.964 67.2694 109.557C67.4139 110.171 68.2707 110.38 68.9736 110.479C75.1681 111.348 81.1964 112.949 87.2025 114.544L93.5025 116.218C95.3191 116.701 97.1673 117.197 98.7369 118.137C100.177 119.003 101.317 120.204 102.03 121.608C102.75 123.033 102.997 124.603 103.254 126.146C104.201 131.827 105.409 137.565 108.281 142.717L93.0014 147.138C91.5201 147.618 89.9855 147.959 88.4232 148.155C86.9338 148.264 85.4346 148.2 83.964 147.964C84.0589 144.954 84.1166 141.852 84.2115 138.841C84.2215 137.89 84.2915 136.941 84.4212 135.996C84.6503 134.554 85.1576 133.15 85.3158 131.7C84.6109 131.187 83.5863 131.314 82.6729 131.338C80.5885 131.391 78.5535 130.752 76.6998 129.909C74.846 129.066 73.1222 128.016 71.2788 127.156C68.2074 125.722 64.7144 124.758 62.3698 122.496C63.564 126.512 66.3854 130.012 66.9312 134.137C67.4826 138.304 65.5606 142.382 63.6792 146.235L57.668 158.545C57.0044 159.905 56.1205 161.432 54.484 161.757C53.4645 161.959 52.4126 161.621 51.4663 161.23C45.9336 158.943 41.5074 154.645 35.5999 153.27C36.0402 150.689 38.3516 148.555 39.6607 146.214C40.7648 144.239 41.1253 142.016 41.861 139.912C42.5966 137.808 43.8607 135.677 46.0835 134.677C47.1389 134.202 48.5608 133.789 48.6593 132.747C48.6662 132.412 48.5858 132.081 48.4244 131.778C47.6328 130.02 46.404 128.442 44.8288 127.159C44.6259 127.021 44.4637 126.842 44.3561 126.637C44.2977 126.434 44.2858 126.223 44.321 126.016C44.4325 124.34 43.4155 122.772 42.2807 121.424C41.146 120.076 39.835 118.793 39.1954 117.212C38.0955 114.493 39.1871 111.505 40.2745 108.782C40.714 107.682 41.1763 106.547 42.0792 105.694C43.4949 104.358 45.6999 103.972 47.7609 103.773C54.425 103.128 62.2455 101.587 67.5409 105.215Z"
			fill="#2F2E41" />
		<g opacity="0.1">
			<path opacity="0.1"
				d="M68.2344 106.028C68.0102 105.987 67.8024 105.895 67.6326 105.76C67.4627 105.624 67.3371 105.451 67.2689 105.259C67.2516 105.183 67.2463 105.106 67.2534 105.029C67.3493 105.091 67.4461 105.15 67.5404 105.215C67.8467 105.429 68.0856 105.709 68.2344 106.028Z"
				fill="black" />
		</g>
		<path
			d="M57.0105 62.6021C56.931 63.9452 57.3905 65.3826 58.5606 66.2444C58.936 66.5209 59.3717 66.7304 59.7328 67.0213C60.0538 67.3121 60.2991 67.6612 60.4516 68.0443C60.6042 68.4273 60.6604 68.835 60.6163 69.2391C60.5722 69.6432 60.4289 70.034 60.1963 70.3843C59.9637 70.7346 59.6475 71.036 59.2695 71.2675C58.0545 71.9217 56.5097 71.7452 55.1074 71.5305C53.2372 71.2998 51.4039 70.8777 49.6444 70.2726C47.8749 69.6592 46.3686 68.5705 45.3386 67.1605C46.8655 66.4092 47.697 64.8312 47.7897 63.2907C47.8826 61.7481 47.4033 59.9976 48.5104 58.801C49.5764 57.6486 51.5827 57.657 53.2078 58.0526C54.0235 58.2511 54.8133 58.5257 55.6301 58.72C56.044 58.8185 57.4112 58.821 57.6817 59.09C58.1734 59.5791 57.0565 61.825 57.0105 62.6021Z"
			fill="#FBBEBE" />
		<path opacity="0.1"
			d="M57.0105 62.6021C56.931 63.9452 57.3905 65.3826 58.5606 66.2444C58.936 66.5209 59.3717 66.7304 59.7328 67.0213C60.0538 67.3121 60.2991 67.6612 60.4516 68.0443C60.6042 68.4273 60.6604 68.835 60.6163 69.2391C60.5722 69.6432 60.4289 70.034 60.1963 70.3843C59.9637 70.7346 59.6475 71.036 59.2695 71.2675C58.0545 71.9217 56.5097 71.7452 55.1074 71.5305C53.2372 71.2998 51.4039 70.8777 49.6444 70.2726C47.8749 69.6592 46.3686 68.5705 45.3386 67.1605C46.8655 66.4092 47.697 64.8312 47.7897 63.2907C47.8826 61.7481 47.4033 59.9976 48.5104 58.801C49.5764 57.6486 51.5827 57.657 53.2078 58.0526C54.0235 58.2511 54.8133 58.5257 55.6301 58.72C56.044 58.8185 57.4112 58.821 57.6817 59.09C58.1734 59.5791 57.0565 61.825 57.0105 62.6021Z"
			fill="black" />
		<path
			d="M52.3262 62.5194C47.3308 62.5194 43.2813 58.9459 43.2813 54.5378C43.2813 50.1297 47.3308 46.5562 52.3262 46.5562C57.3216 46.5562 61.3711 50.1297 61.3711 54.5378C61.3711 58.9459 57.3216 62.5194 52.3262 62.5194Z"
			fill="#FBBEBE" />
		<path
			d="M80.8863 115.774C81.0487 116.404 81.3371 117.005 81.7378 117.546C82.7923 119.199 83.1734 121.122 82.8174 122.993C82.6265 123.921 82.1799 124.793 81.5169 125.533C80.8538 126.272 79.9948 126.856 79.0154 127.234C78.1687 127.528 77.1198 127.606 76.4216 127.091C77.63 125.704 77.6941 123.81 77.6218 122.061C77.6427 121.735 77.5545 121.41 77.3681 121.128C77.3185 121.061 77.2538 121.003 77.178 120.959C77.1021 120.914 77.0168 120.884 76.9273 120.87C76.8378 120.856 76.7459 120.859 76.6575 120.877C76.569 120.896 76.4858 120.93 76.413 120.978C76.4437 122.079 76.2026 123.173 75.7064 124.183C75.5152 124.572 75.1882 125.001 74.7082 124.99C74.0194 124.974 73.8334 124.151 73.8595 123.543C73.966 121.068 74.1034 118.49 75.4558 116.319C75.9508 115.524 76.6106 114.778 76.7988 113.887C76.8078 113.577 76.9289 113.279 77.1445 113.035C77.2937 112.932 77.4678 112.86 77.6534 112.826C78.432 112.632 80.2751 112.027 80.9177 112.671C81.6067 113.361 80.7616 114.949 80.8863 115.774Z"
			fill="#FBBEBE" />
		<path
			d="M67.4542 73.3662C68.4915 73.2632 69.3104 74.101 69.8788 74.8736C71.996 77.7547 73.5199 80.943 74.3803 84.2918C74.8185 86.0011 75.0938 87.7764 76.0133 89.3297C76.2347 89.6521 76.4131 89.9961 76.5448 90.3547C76.6234 90.9025 76.6511 91.455 76.6276 92.0067C76.7235 93.004 77.3911 93.8899 77.6153 94.8711C77.749 95.4558 77.722 96.0612 77.8408 96.6485C78.049 97.677 78.6895 98.5917 79.0992 99.572C79.8206 101.298 79.8142 103.193 80.345 104.972C80.4099 105.265 80.5536 105.54 80.7641 105.774C81.124 106.123 81.6992 106.208 82.17 106.432C82.5404 106.632 82.858 106.899 83.1016 107.217C83.3452 107.534 83.509 107.893 83.5822 108.271C83.7226 109.027 83.7388 109.796 83.6303 110.556L83.2145 115.277C82.786 115.118 82.5062 114.691 82.1489 114.428C81.4949 113.949 80.5345 114.056 79.6977 114.19L78.4787 114.385C77.2318 114.584 75.8757 114.839 75.0758 115.706C74.4402 114.829 74.8036 113.536 73.8267 112.953C73.5089 112.763 73.1155 112.699 72.7804 112.533C72.465 112.353 72.2035 112.109 72.0182 111.82C71.8328 111.532 71.7291 111.208 71.7159 110.877C71.6966 110.215 71.8001 109.554 72.0221 108.921C70.4562 108.338 69.485 106.939 68.8724 105.54C68.1983 103.846 67.7106 102.1 67.416 100.324C66.0016 93.3075 64.7085 86.241 64.5801 79.1155C64.4908 77.9236 64.6398 76.7271 65.0205 75.5808C65.4663 74.4558 66.112 73.4994 67.4542 73.3662Z"
			fill="#D0CDE1" />
		<path
			d="M44.6513 65.7251C46.1443 67.1069 48.0525 68.0849 50.1525 68.5445C52.2414 68.9979 54.3744 69.2752 56.5228 69.3726C57.1833 69.4703 57.8612 69.4186 58.4929 69.2225C59.1003 68.9796 59.5619 68.345 59.3147 67.7985C59.195 67.6295 59.1142 67.4413 59.0769 67.2448C59.0875 66.7586 59.8982 66.7183 60.4247 66.862C63.9066 67.8123 67.2457 69.6942 68.8527 72.5809C69.7604 74.2115 70.06 76.0452 70.3478 77.8441C71.1047 82.5755 71.8597 87.4099 70.8451 92.1036C70.3041 94.6065 69.2633 97.0462 69.1983 99.5935C69.1619 101.021 69.4348 102.443 69.4221 103.871C69.4703 104.371 69.3186 104.87 68.9934 105.282C68.7429 105.539 68.3651 105.701 68.1904 106.002C67.8983 106.507 68.2888 107.199 67.9042 107.653C67.4305 108.212 66.4414 107.81 65.7889 107.416C62.5067 105.45 58.5428 104.569 54.5966 104.93C49.856 105.379 45.1455 107.608 40.548 106.494C40.7847 106.19 40.989 105.866 41.1581 105.529C43.4507 98.881 43.2955 91.4503 39.8645 85.1959C38.6924 83.0592 37.1369 81.0229 36.6879 78.6823C36.5068 77.5876 36.4925 76.4763 36.6454 75.3782C36.887 73.2534 37.4604 71.111 38.7691 69.3146C40.0777 67.5183 42.2431 65.9354 44.6513 65.7251Z"
			fill="#D0CDE1" />
		<path
			d="M66.5333 117.828C66.9016 118.696 67.0783 119.618 67.0541 120.545C67.0881 121.431 67.0381 122.318 66.9044 123.197C66.8276 123.949 66.5242 124.668 66.0258 125.281C65.7706 125.582 65.42 125.811 65.0182 125.937C64.6163 126.063 64.1812 126.081 63.7676 125.989C63.4422 125.87 63.151 125.688 62.9154 125.457C62.6797 125.226 62.5055 124.951 62.4054 124.653C62.2107 124.056 62.0678 123.448 61.9779 122.833C61.5813 121.021 60.5033 119.32 60.4845 117.475C60.4695 117.256 60.5087 117.037 60.5996 116.833C60.9917 116.075 62.9128 115.224 63.8515 115.351C65.0258 115.511 66.1072 116.952 66.5333 117.828Z"
			fill="#FBBEBE" />
		<path opacity="0.1"
			d="M40.4317 72.4636C40.6107 74.0505 38.8709 75.2466 38.1832 76.7214C37.902 77.4099 37.7419 78.1324 37.7086 78.8636C37.2253 83.9378 37.6007 89.0417 37.9761 94.1231C38.0447 95.8147 38.2824 97.4977 38.6867 99.1524C39.6576 102.625 42.0459 105.635 44.3864 108.555C45.1907 109.725 46.421 110.621 47.8764 111.095L55.9073 114.528C56.9136 114.92 57.8698 115.405 58.76 115.975C59.7933 116.69 60.6055 117.623 61.1215 118.688C62.6795 117.986 64.2973 117.355 65.8554 116.652C66.22 116.488 66.6278 116.273 66.6908 115.916C66.7752 115.437 66.2078 115.099 65.8172 114.758C65.0865 114.12 64.8612 113.174 64.4656 112.337C63.1731 109.602 59.9224 107.851 58.7498 105.074C58.2992 104.007 58.1615 102.801 57.3624 101.907C56.9053 101.458 56.3911 101.058 55.8302 100.713C54.592 99.7862 53.4548 98.7588 52.4332 97.6448C51.9743 97.2246 51.5987 96.7393 51.3222 96.2092C50.9713 95.4211 51.0763 94.5424 51.058 93.6958C50.9983 90.9241 49.5782 88.3392 48.7087 85.6754C47.8899 83.1668 47.5482 80.5352 46.4742 78.1028C45.4001 75.6703 43.2958 73.1176 40.4317 72.4636Z"
			fill="black" />
		<path
			d="M39.3887 72.4636C39.5677 74.0505 37.8279 75.2466 37.1402 76.7214C36.859 77.4099 36.6989 78.1324 36.6656 78.8636C36.1823 83.9378 36.5576 89.0417 36.9331 94.1231C37.0016 95.8147 37.2394 97.4977 37.6437 99.1524C38.6146 102.625 41.0029 105.635 43.3434 108.555C44.1477 109.725 45.378 110.621 46.8334 111.095L54.8643 114.528C55.8706 114.92 56.8269 115.405 57.717 115.975C58.7503 116.69 59.5625 117.623 60.0785 118.688C61.6365 117.986 63.2543 117.355 64.8124 116.652C65.1771 116.488 65.5849 116.273 65.6478 115.916C65.7323 115.437 65.1648 115.099 64.7743 114.758C64.0436 114.12 63.8182 113.174 63.4226 112.337C62.1301 109.602 58.8794 107.851 57.7069 105.074C57.2562 104.007 57.1185 102.801 56.3194 101.907C55.8623 101.458 55.3482 101.058 54.7872 100.713C53.549 99.7862 52.4117 98.7588 51.3902 97.6448C50.9313 97.2246 50.5557 96.7393 50.2792 96.2092C49.9282 95.4211 50.0333 94.5424 50.0151 93.6958C49.9553 90.9241 48.5352 88.3392 47.6657 85.6754C46.8469 83.1668 46.5053 80.5352 45.4312 78.1028C44.3571 75.6703 42.2528 73.1176 39.3887 72.4636Z"
			fill="#D0CDE1" />
		<path
			d="M47.8401 42.1989C50.627 41.8983 53.4486 42.4138 55.8711 43.6661C55.5142 43.6779 55.1556 43.6994 54.7946 43.7379C52.118 44.0231 49.6105 45.0427 47.6259 46.653C45.6413 48.2633 44.2799 50.3829 43.7337 52.7127C43.1875 55.0424 43.4842 57.4647 44.5819 59.6377C45.6796 61.8106 47.5228 63.6246 49.8515 64.8235C46.5335 64.9303 43.2972 63.9015 40.8187 61.952C38.3402 60.0025 36.8114 57.2832 36.5517 54.3623C36.292 51.4414 37.3216 48.545 39.4252 46.2783C41.5289 44.0116 44.5438 42.55 47.8401 42.1989Z"
			fill="#2F2E41" />
		<path
			d="M43.8805 48.5132C43.5679 49.1378 43.398 49.8107 43.3805 50.4934C43.3631 51.1761 43.4984 51.855 43.7788 52.4915C43.8397 52.3269 43.9101 52.1653 43.9898 52.0071C45.5808 48.8832 50.4819 47.5901 54.9367 49.119C57.5611 50.0196 59.4439 51.6989 60.2238 53.5653C61.3582 50.5454 59.0544 47.0757 54.8274 45.625C50.3726 44.0962 45.4715 45.3893 43.8805 48.5132Z"
			fill="#2F2E41" />
		<g opacity="0.1">
			<path opacity="0.1"
				d="M43.8557 52.2437C44.0615 50.0511 44.9836 47.9584 46.5104 46.2191C48.0371 44.4798 50.1028 43.1687 52.4571 42.4448C53.6556 42.7052 54.8054 43.1166 55.8711 43.6661C55.5142 43.6779 55.1556 43.6994 54.7946 43.7379C52.2106 44.0136 49.782 44.9741 47.8291 46.4926C45.8762 48.011 44.4909 50.0162 43.8557 52.2437Z"
				fill="black" />
			<path opacity="0.1"
				d="M41.7678 43.9551C41.9736 41.7626 42.8957 39.6698 44.4225 37.9305C45.9492 36.1912 48.0149 34.8802 50.3692 34.1562C51.5677 34.4167 52.7175 34.828 53.7832 35.3776C53.4263 35.3893 53.0677 35.4108 52.7067 35.4493C50.1227 35.725 47.6941 36.6855 45.7412 38.204C43.7884 39.7225 42.4029 41.7277 41.7678 43.9551Z"
				fill="black" />
			<path opacity="0.1"
				d="M41.7153 31.5751C41.8395 30.2518 42.396 28.9888 43.3174 27.9392C44.2388 26.8895 45.4856 26.0982 46.9064 25.6613C47.6297 25.8185 48.3236 26.0667 48.9668 26.3984C48.7514 26.4055 48.5349 26.4185 48.3171 26.4417C46.7576 26.6081 45.2919 27.1878 44.1133 28.1042C42.9347 29.0206 42.0986 30.2308 41.7153 31.5751Z"
				fill="black" />
			<path opacity="0.1"
				d="M36.9012 50.7047C36.651 53.4286 37.5211 56.1408 39.3523 58.3446C41.1835 60.5484 43.8532 62.0963 46.8723 62.7047C47.741 63.5372 48.7451 64.2513 49.8516 64.8235C47.8559 64.8878 45.8707 64.5415 44.053 63.8119C42.2353 63.0822 40.6349 61.9893 39.3785 60.6196C38.1222 59.2498 37.2443 57.6409 36.8143 55.92C36.3843 54.1992 36.4141 52.4136 36.9012 50.7047V50.7047Z"
				fill="black" />
		</g>
		<path d="M76.9371 123.482L78.6016 79.0769" stroke="#15A587" stroke-width="2" stroke-miterlimit="10" />
		<path
			d="M78.6026 85.3599C74.6704 85.3599 71.4826 82.5469 71.4826 79.0769C71.4826 75.607 74.6704 72.794 78.6026 72.794C82.5349 72.794 85.7227 75.607 85.7227 79.0769C85.7227 82.5469 82.5349 85.3599 78.6026 85.3599Z"
			fill="#15A587" />
		<path d="M77.5765 106.445C77.5765 106.445 79.3163 87.1803 100.072 90.0312L77.5765 106.445Z" fill="#15A587" />
	</g>
	<defs>
		<linearGradient id="paint0_linear" x1="46.0896" y1="61.755" x2="46.0896" y2="56.8875"
			gradientUnits="userSpaceOnUse">
			<stop stop-color="#808080" stop-opacity="0.25" />
			<stop offset="0.53514" stop-color="#808080" stop-opacity="0.12" />
			<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
		</linearGradient>
		<clipPath id="clip0">
			<rect width="422" height="261" fill="white" transform="matrix(-1 0 0 1 422 0)" />
		</clipPath>
	</defs>
</svg> -->

<img src="assets/welcome-image.jpeg" alt="imagem" />
