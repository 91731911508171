import { Component } from '@angular/core';

@Component({
  selector: 'facebook-icon',
  templateUrl: './facebook-icon.component.html',
  styleUrls: ['./facebook-icon.component.css']
})
export class FacebookIconComponent {

  constructor() { }

}
