import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'email-icon',
  templateUrl: './email-icon.component.html',
  styleUrls: ['./email-icon.component.css']
})
export class EmailIconComponent{
}
